import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import darkexilebg from './darkexile.jpg';
import rogue_encampment from './Rogue_Encampment.png';
import d2icon from './d2icon.jpg';
import d2quest from './d2quest.png';
import d2reddit from './redditicon.png';
import d2discord from './discordicon.png';
import d2nwnicon from './nwnlogo.jpg';
import item_the_gnasher from './The_Gnasher.png';
import item_dimoaks_hew from './Dimoaks_Hew.png';
import item_pluckeye from './Pluckeye.png';
import item_pelta_lunata from './Pelta_Lunata.png';
import item_hsarus_iron_fist from './Hsarus_Iron_Fist.png';
import item_hsarus_iron_heel from './Hsarus_Iron_Heel.png';
import item_hsarus_iron_stay from './Hsarus_Iron_Stay.png';
import './App.css';
import { FormFloating } from 'react-bootstrap';
import React, { Component,useState } from 'react';
import { render } from 'react-dom';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SlidingPanel from 'react-sliding-side-panel';
import ReactPlayer from 'react-player';
//import myVideo from './vid1.mp4';
import VideoPlayer from 'react-video-player-extended';


function App() {
  const [isShown, setIsShown] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [isShown3, setIsShown3] = useState(false);
  const [isShown4, setIsShown4] = useState(false);
  const [isShown5, setIsShown5] = useState(false);
  const [isShown6, setIsShown6] = useState(false);
  const [isShown7, setIsShown7] = useState(false);
  const [isShown8, setIsShown8] = useState(false);
  const [isShown9, setIsShown9] = useState(false);

  const [openPanel, setOpenPanel] = useState(false);

  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });


  return (
    
    <div className="App">
  

     <body className="App-body">

      <div class="noselect">
     <img className="App-logo" src={darkexilebg}/>
     </div>

     <div class="noselect"> 
      <ul class="nav">

        <li onClick={() => setState({ isPaneOpen: true })} id="pbuttonh" className="playbuttonhover" type="button">


        <img className="d2icon" src={d2icon}/>
          <a id="pnowt" className="playnow" > PLAY NOW!&nbsp; </a>
          
        </li>

        <li id="pbuttonh" className="playbuttonhover" type="button">


        <img className="d2quest" src={d2quest}/>
          <a id="pnowt" className="playnow" > GAME GUIDE&nbsp; </a>
          
        </li>

        <li id="pbuttonh" className="playbuttonhover" type="button">

        <a href="https://www.reddit.com/r/DarkExile" target="_blank" id="pnowt" className="playnow">
        <img className="d2reddit" src={d2reddit}/>
          <a id="pnowt" className="playnow" > FORUM&nbsp; </a></a>
          
        </li>

        <li id="pbuttonh" className="playbuttonhover" type="button">

        <a href="https://discord.gg/fHzguea" target="_blank" id="pnowt" className="playnow">
        <img className="d2discord" src={d2discord}/>
          <a id="pnowt" className="playnow" > DISCORD&nbsp; </a></a>
          
        </li>        

        <li id="pbuttonh" className="playbuttonhover" type="button">


        <img className="d2nwnicon" src={d2nwnicon}/>
          <a id="pnowt" className="playnow" > ABOUT&nbsp; </a>
          
        </li>

      </ul>      
       
         <div class="noselect">
         <hr className="redborder2"></hr>
         </div>

      </div>





      <div className="playnow_slidepage_x">
    {/*  <button onClick={() => setState({ isPaneOpen: true })}>
        Click me to open right pane!
  </button> */}
     {/* <div style={{ marginTop: "32px" }}>
        <button onClick={() => setState({ isPaneOpenLeft: true })}>
          Click me to open left pane with 20% width!
        </button>
  </div> */}
      <SlidingPane
         width="50%"
         background-color="#000000"
         color="#000000"
        size={20}
        className="playnow_slidepage"
        overlayClassName="playnow_slidepage"
        isOpen={state.isPaneOpen}
        title="How to play Dark Exile"
        /*subtitle="Optional subtitle."*/
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setState({ isPaneOpen: false });
        }}
      >
        <div className="testxxx"><br/>To Play Dark Exile you have to buy Neverwinter Nights EE<br/>You can buy it on <p><a href="https://store.steampowered.com/app/704450/Neverwinter_Nights_Enhanced_Edition/" target="_blank">Steam</a></p> on <p><a href="https://www.gog.com/game/neverwinter_nights_enhanced_edition_pack" target="_blank">gog.com</a></p> or on <p><a href="https://store.beamdog.com/products/neverwinter-nights-enhanced-edition" target="_blank">beamdog.com</a></p><img src={rogue_encampment}/>Run Neverwinter Nights and click Multiplayer, then Join Internet Game<br/>Find Server named Diablo II Dark Exile and join</div>
      </SlidingPane>
      {/*<SlidingPane
        closeIcon={<div>Some div containing custom close icon.</div>}
        isOpen={state.isPaneOpenLeft}
        title="Hey, it is optional pane title.  I can be React component too."
        from="left"
        width="200px"
        onRequestClose={() => setState({ isPaneOpenLeft: false })}
      >
        <div>And I am pane content on left.</div>
      </SlidingPane> */}
    </div>

   


   {/*old player should be deleted at some point*/}
    {/*<div className='player-wrapper'>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= {myVideo}  
            width='50%'
            height='50%'
            controls = {true}
            frames='5'
            />
        </div>*/}





         <h1 className="font-face-diablo-l">WHAT IS DARK EXILE DIABLO II?</h1>
         <p className="zindex">Dark Exile is a Diablo II Mod, made for Neverwinter Nights (NWN).<br></br>
          The Mod tries to stay as true to the original Diablo II as possible.<br></br>
          Even if you have never played NWN, you will find this Mod to be a lot like the original Diablo II.<br></br>
          Play solo or with your friends, from Act 1 to Act 5. After Act 5 there is a map system similar to Path of Exile!<br></br>
          The item system is completely the same as Diablo II, Unique and Set items are very much alike too.<br></br>
          Special features in the game include Gambling like Blackjack, Chicken Game, and Keno.
        <div class="noselect">
         <hr className="redborder"></hr>
         </div>
         <h2 className="font-face-diablo-l">DARK EXILE ACT 1 CINEMATIC</h2>

         <div class="container"> 
         <div class="noselect">
         <iframe class="responsive-iframe" width="560" height="315" src="https://www.youtube.com/embed/AMiZFpY9w1Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
         </div>
         </div>
         <div class="noselect">
         <hr className="redborder"></hr>
         </div>
         <div class="noselect"><h2 className="font-face-diablo-l">UNIQUE ITEMS</h2><br></br></div>

         <div className="item1description">

         <div class="item1div"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
          <div class="noselect">
        <img className="item_the_gnasher" src={item_the_gnasher}></img>
        </div>
      </div>
      {isShown && (
        <div className="item1p">
          <span className="item1p"><p className="item1s">
          <br></br>
            <span className="uniqnamecolor">The Gnasher</span><br></br>(Hand Axe)<br></br><br></br>
2 to 1d6 Slashing Damage (variates)<br></br>
+4 Str<br></br>
Extra Melee Damage: Bludgeoning<br></br>
Extra Melee Damage: Piercing<br></br>
Keen<br></br>
Light Dim Yellow<br></br>
2d12 Massive Criticals<br></br>
On Hit: Wounding (DC=16)
<br></br>
<br></br>

             
          </p></span>
          
        </div>
      )}
         


</div>






<div className="item1description">

<div class="item1div"
onMouseEnter={() => setIsShown2(true)}
onMouseLeave={() => setIsShown2(false)}>
 <div class="noselect">
<img className="item_dimoaks_hew" src={item_dimoaks_hew}></img>
</div>
</div>
{isShown2 && (
<div className="item1p">
 <span className="item1p"><p className="item1s">
 <br></br>
   <span className="uniqnamecolor">Dimoaks Hew</span><br></br>(Bardiche)<br></br><br></br>
   1d6 Bludgeoning Damage<br></br>
   1d6 to 1d10 Slashing Damage (variates)<br></br>
   -2 AC<br></br>
   +7 Dex<br></br>
Keen<br></br>
Light Dim Yellow<br></br>
On Hit: Horizikaul's Boom (1)
<br></br>
<br></br>

    
 </p></span>
 
</div>
)}



</div>







<div className="item1description">

<div class="item1div"
onMouseEnter={() => setIsShown3(true)}
onMouseLeave={() => setIsShown3(false)}>
 <div class="noselect">
<img className="item_pluckeye" src={item_pluckeye}></img>
</div>
</div>
{isShown3 && (
<div className="item1p">
 <span className="item1p"><p className="item1s">
 <br></br>
   <span className="uniqnamecolor">Pluckeye</span><br></br>(Short Bow)<br></br><br></br>
   +3 Attack Bonus<br></br>
   +2 Wis<br></br>
   Light Normal Blue<br></br>
   +8 Mighty<br></br>
   +1 Regeneration<br></br>
   1d8 Magical Damage<br></br>
On Hit: Horizikaul's Boom (3)
<br></br>
<br></br>

    
 </p></span>
 
</div>
)}



</div>






<div className="item1description">

<div class="item1div"
onMouseEnter={() => setIsShown4(true)}
onMouseLeave={() => setIsShown4(false)}>
 <div class="noselect">
<img className="item_pelta_lunata" src={item_pelta_lunata}></img>
</div>
</div>
{isShown4 && (
<div className="item1p">
 <span className="item1p"><p className="item1s">
 <br></br>
   <span className="uniqnamecolor">Pelta Lunata</span><br></br>(Buckler)<br></br><br></br>
   +2 AC<br></br>
   +2 or +3 Cha<br></br>
   +2 Con<br></br>
   +2 Str<br></br>
   +2 or +3 Wis<br></br>
<br></br>

    
 </p></span>
 
</div>
)}



</div>




<div className="z_index_b">
<div class="noselect">
<br></br><br></br><br></br>
<br></br><br></br><br></br><div className="absolute_pos_wrapper"><div class="noselect"><hr className="redborder"></hr></div></div><div className="setitemstitel"><h2 className="font-face-diablo-l">SET ITEMS</h2></div><div className="setitemstitel"><h2 className="font-face-diablo-l-color">HSARUS DEFENSE SET (Example)</h2></div><br></br><br></br>
</div>
</div>


<div className="item2description">

<div class="item1div"
onMouseEnter={() => setIsShown5(true)}
onMouseLeave={() => setIsShown5(false)}>
 <div class="noselect">
<img className="item_hsarus_iron_fist" src={item_hsarus_iron_fist}></img>
</div>
</div>
{isShown5 && (
<div className="item1p">
 <span className="item1p"><p className="item1s">
 <br></br>
   <span className="setnamecolor">Hsarus Iron Fist</span><br></br>(Buckler)<br></br><br></br>
   +4 Str<br></br>
   +1 Saving Throw Bonus Vs All (Universal)<br></br>
<br></br>

    
 </p></span>
 
</div>
)}



</div>











<div className="item2description">

<div class="item1div"
onMouseEnter={() => setIsShown6(true)}
onMouseLeave={() => setIsShown6(false)}>
 <div class="noselect">
<img className="item_hsarus_iron_fist" src={item_hsarus_iron_heel}></img>
</div>
</div>
{isShown6 && (
<div className="item1p">
 <span className="item1p"><p className="item1s">
 <br></br>
   <span className="setnamecolor">Hsarus Iron Heel</span><br></br>(Boots)<br></br><br></br>
   Cast Spell: Haste(10)[1 use/day]<br></br>
   25% Fire Immunity<br></br>
   +1 Regeneration<br></br>
<br></br>

    
 </p></span>
 
</div>
)}



</div>






<div className="item2description">

<div class="item1div"
onMouseEnter={() => setIsShown7(true)}
onMouseLeave={() => setIsShown7(false)}>
 <div class="noselect">
<img className="item_hsarus_iron_stay" src={item_hsarus_iron_stay}></img>
</div>
</div>
{isShown7 && (
<div className="item1p">
 <span className="item1p"><p className="item1s">
 <br></br>
   <span className="setnamecolor">Hsarus Iron Stay</span><br></br>(Belt)<br></br><br></br>
   +2 Con<br></br>
   10% Cold Immunity<br></br>
   +1 Regeneration<br></br>
<br></br>

    
 </p></span>
 
</div>
)}



</div>

<div class="noselect">
<br></br><br></br><br></br> <div className="absolute_pos_wrapper"><div class="noselect"><hr className="redborder"></hr></div></div>
</div>
<div className="setitemstitel"><h4 className="font-face-diablo-l">FULL SET ITEM BONUSES</h4></div>
<p className="setitemstitel"> -- Hsarus' Defense --</p>
<p className="setitemstitel">Hsarus' Iron Fist - Shield</p>
<p className="setitemstitel">Hsarus' Iron Stay - Belt</p>
<p className="setitemstitel">Hsarus' Iron Heel - Boots</p>
<p className="setitemstitel">-- Full Set Bonus --</p>
<p className="fontsize_setitems">
Extra attacks per round 1<br></br>
Movement speed increase 40%<br></br>
Hsarus' Iron Fist - Shield
+1 AC (Armor Bonus) per 5 Character Lvl (2 set items equipped)<br></br>
Hsarus' Iron Stay - Belt
+1 AC (Armor Bonus) per 5 Character Lvl (2 set items equipped)<br></br>
Hsarus' Iron Heel - Boots
+1 Strength per 10 Character Lvl (2 set items equipped)<br></br>
+1 Dexterity per 10 Character Lvl (2 set items equipped)<br></br>
10% Cold Immunity (All set items equipped)
10% Electrical Immunity (All set items equipped)</p>

<div class="noselect"><br></br>
         <hr className="redborder"></hr>
         </div>



    <div className="playnow_rezize">
         <div class="noselect"> 
<li onClick={() => setState({ isPaneOpen: true })} id="pbuttonh" className="playbuttonhover" type="button">

<img className="d2icon" src={d2icon}/>
  <a id="pnowt" className="playnow" > PLAY NOW!&nbsp; </a>
  
</li>
</div>
</div>

<div class="noselect">
         <hr className="redborder"></hr>
         </div>

<div class="noselect">
  <p className="font-face-diablo-l">© 2021 by Anirml</p>
</div>





<div class="noselect">
<br></br>
</div>


</p>

   {/*      <div class="item1div"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
        <img className="d2discord" src={d2discord}></img>
      </div>
      {isShown && (
        <div>
          <p className="item1p">
            hmm
          </p>
          
        </div>
      )}
         </p>


    </div>    */}






     </body>

          <script>
          {


          }
          </script>
    </div>
  );
}

var ptext =  <p id="pnowt" className="playnow2"> PLAY NOW! </p>

class Apps extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
 
  handleClick = (event) => {
      alert(event.target.innerText);    // Click Me
      alert(event.target.tagName);      // BUTTON
  }
 
  render() {
    return (
      <div>
        <div className="text-center">
          <button className="btn btn-secondary" onClick={this.handleClick}>
            Click Me
          </button>
        </div>
      </div>
    );
  }
}






export default App;
